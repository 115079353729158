<template>
  <div>
    <a-card title="商品销售额 TOP10">
      <div slot="extra">
        <a-button class="card-buttom">数据导出</a-button>
      </div>

      <div id="salesAmountChart" style="margin-top: 32px;"></div>
    </a-card>
  </div>
</template>

<script>
  import * as G2 from '@antv/g2'

  export default {
    name: 'SalesAmountChart',
    props: [],
    data() {
      return {
        chart: null,
      };
    },
    methods: {
      initialize() {
        let height = 320;
        this.chart = new G2.Chart({ container: 'salesAmountChart', autoFit: true, height });
        this.chart.scale({ date: { range: [0, 1] }, amount: { nice: true } });
        this.chart.tooltip({ showCrosshairs: true, shared: true });
        this.chart.line().position('date*amount').color('goods_name').shape('smooth');
        this.chart.point().position('date*amount').color('goods_name').shape('circle');

        // 模拟数据
        const data = [
          { date: '2020-01-01', goods_name: 'Tokyo', amount: 2 },
          { date: '2020-01-02', goods_name: 'London', amount: 5 },
          { date: '2020-01-03', goods_name: 'Tokyo', amount: 6 },
          { date: '2020-01-04', goods_name: 'London', amount: 6 },
          { date: '2020-01-05', goods_name: 'Tokyo', amount: 7 },
          { date: '2020-01-06', goods_name: 'London', amount: 5 },
          { date: '2020-01-07', goods_name: 'Tokyo', amount: 19 },
          { date: '2020-01-08', goods_name: 'London', amount: 18 },
          { date: '2020-01-09', goods_name: 'Tokyo', amount: 11 },
          { date: '2020-01-10', goods_name: 'London', amount: 15 },
          { date: '2020-01-11', goods_name: 'Tokyo', amount: 11 },
          { date: '2020-01-12', goods_name: 'London', amount: 4 },
          { date: '2020-01-13', goods_name: 'Tokyo', amount: 10 },
          { date: '2020-01-14', goods_name: 'London', amount: 15 },
          { date: '2020-01-15', goods_name: 'Tokyo', amount: 18 },
          { date: '2020-01-16', goods_name: 'London', amount: 19 },
          { date: '2020-01-17', goods_name: 'Tokyo', amount: 23 },
          { date: '2020-01-18', goods_name: 'London', amount: 14 },
          { date: '2020-01-19', goods_name: 'Tokyo', amount: 6 },
          { date: '2020-01-20', goods_name: 'London', amount: 8 },
          { date: '2020-01-21', goods_name: 'Tokyo', amount: 5 },
          { date: '2020-01-22', goods_name: 'London', amount: 10 },
          { date: '2020-01-23', goods_name: 'Tokyo', amount: 16 },
          { date: '2020-01-24', goods_name: 'London', amount: 10 },

          { date: '2020-01-01', goods_name: 'London', amount: 7 },
          { date: '2020-01-02', goods_name: 'Tokyo', amount: 3.9 },
          { date: '2020-01-03', goods_name: 'London', amount: 6.9 },
          { date: '2020-01-04', goods_name: 'Tokyo', amount: 4.2 },
          { date: '2020-01-05', goods_name: 'London', amount: 9.5 },
          { date: '2020-01-06', goods_name: 'Tokyo', amount: 5.7 },
          { date: '2020-01-07', goods_name: 'London', amount: 14.5 },
          { date: '2020-01-08', goods_name: 'Tokyo', amount: 8.5 },
          { date: '2020-01-09', goods_name: 'London', amount: 18.4 },
          { date: '2020-01-10', goods_name: 'Tokyo', amount: 11.9 },
          { date: '2020-01-11', goods_name: 'London', amount: 21.5 },
          { date: '2020-01-12', goods_name: 'Tokyo', amount: 15.2 },
          { date: '2020-01-13', goods_name: 'London', amount: 25.2 },
          { date: '2020-01-14', goods_name: 'Tokyo', amount: 17 },
          { date: '2020-01-15', goods_name: 'London', amount: 26.5 },
          { date: '2020-01-16', goods_name: 'Tokyo', amount: 16.6 },
          { date: '2020-01-17', goods_name: 'London', amount: 23.3 },
          { date: '2020-01-18', goods_name: 'Tokyo', amount: 14.2 },
          { date: '2020-01-19', goods_name: 'London', amount: 18.3 },
          { date: '2020-01-20', goods_name: 'Tokyo', amount: 10.3 },
          { date: '2020-01-21', goods_name: 'London', amount: 13.9 },
          { date: '2020-01-22', goods_name: 'Tokyo', amount: 6.6 },
          { date: '2020-01-23', goods_name: 'London', amount: 9.6 },
          { date: '2020-01-24', goods_name: 'Tokyo', amount: 4.8 },
        ];
        this.chart.changeData(data);
        this.chart.forceFit();
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
  .card-buttom {
    margin: -4px 0;
  }
</style>